/*
 * @title App
 * @description Application entry point
 */

// Modules
import inview from './components/inviewport';
import navbar from './components/navbar';

document.addEventListener('DOMContentLoaded', function() {
  inview();
  navbar();
})

