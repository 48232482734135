export function inview() {
    $(document).ready(function() {
        new WOW().init();
        setTimeout(function() { 
            $(".loader").fadeOut();
        }, 1000);
    });
}

export default inview;

